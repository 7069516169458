import { useEffect } from "react";
import type { InferGetStaticPropsType, NextPage } from "next";
import { Supply } from "@components/ui/Pages";
import { supportedChainIds } from "@lib/connectors";
import { usePools, useWeb3 } from "@lib/hooks";
import { usePoolsStore } from "@lib/providers";
import { getDeployedPools } from "@lib/utils";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStaticProps = async () => {
  const pools = await getDeployedPools(supportedChainIds);

  return {
    props: {
      pools,
    },
  };
};

const SupplyPage: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  pools,
}): JSX.Element => {
  const { chainId } = useWeb3();
  const addPools = usePoolsStore((state) => state.addPools);
  const { lendingPools } = usePools();

  useEffect(() => {
    if (!chainId) return;

    // if pools are already added to the store, don't add them again
    if (lendingPools.length > 0) return;

    addPools(pools[chainId], chainId);
  }, [chainId]);

  return <Supply />;
};

export default SupplyPage;
